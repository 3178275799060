<template>
  <div class="whatsapp-integration mt-5 pt-5 px-3">
    <!-- Hero Section -->
    <section class="hero bg-gradient pt-5">
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-lg-10 text-center">
            <h1 class="display-4 fw-bold mb-3">
              Potencia tu Inmobiliaria con WhatsApp Integrado
            </h1>
            <p class="lead mb-3">
              La primera solución que unifica la gestión inmobiliaria con la
              comunicación vía WhatsApp
            </p>
            <button
              @click="startTrial"
              class="btn btn-primary btn-lg px-4 me-3"
            >
              Prueba Gratuita
            </button>
          </div>
          <div class="col-lg-12 whatsapp-screenshot-container">
            <img
              src="@/assets/images/crm_screenshots/whatsapp-panel.jpg"
              alt="Whatsapp chats"
              class="px-0 whatsapp-screenshot rounded-4"
            />
          </div>
        </div>
      </div>
    </section>

    <!-- Features Section -->
    <section class="features py-5">
      <div class="container">
        <h2 class="text-center mb-5">Características Principales</h2>
        <div class="row g-4">
          <div
            v-for="(feature, index) in features"
            :key="index"
            class="col-md-4"
          >
            <div class="card h-100 border-0 shadow-sm">
              <div class="card-body p-4">
                <div
                  class="feature-icon bg-primary bg-gradient text-white rounded-circle p-3 mb-4"
                >
                  <i :class="feature.icon"></i>
                </div>
                <h3 class="h5 mb-3">{{ feature.title }}</h3>
                <p class="card-text text-muted">{{ feature.description }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Benefits Section -->
    <section class="benefits py-5 bg-light">
      <div class="container">
        <div class="row align-items-center justify-content-between px-1">
          <div class="col-lg-5">
            <h2 class="mb-4">¿Por qué integrar WhatsApp?</h2>
            <div v-for="(benefit, index) in benefits" :key="index" class="mb-4">
              <div class="d-flex align-items-center">
                <div class="benefit-icon me-3">
                  <i :class="benefit.icon + ' text-primary fs-2'"></i>
                </div>
                <div>
                  <h4 class="h6 mb-2">{{ benefit.title }}</h4>
                  <p class="text-muted mb-0">{{ benefit.description }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-5">
            <div class="position-relative">
              <img
                src="@/assets/illustrations/comunication.svg"
                alt="WhatsApp Integration"
                class="img-fluid rounded-4 integration-image"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <CTABanner />
</template>

<script setup>
import { onMounted, ref } from 'vue'

const features = ref([
  {
    icon: "fa fa-users",
    title: "Gestión Centralizada",
    description:
      "Administra todas tus conversaciones de WhatsApp en un solo lugar, asignando chats a diferentes agentes.",
  },
  {
    icon: "fa fa-link",
    title: "Vinculación Inteligente",
    description:
      "Conecta automáticamente las conversaciones con propiedades y filtros de búsqueda relevantes.",
  },
  {
    icon: "fa fa-reply",
    title: "Respuestas Rápidas",
    description:
      "Crea y utiliza plantillas personalizadas para responder eficientemente a consultas frecuentes.",
  },
])

const benefits = ref([
  {
    icon: "fa fa-clock",
    title: "Mayor Eficiencia",
    description:
      "Optimiza el tiempo de respuesta y la gestión de consultas de clientes potenciales.",
  },
  {
    icon: "fa fa-users",
    title: "Trabajo Colaborativo",
    description:
      "Permite que múltiples agentes trabajen sobre la misma línea de WhatsApp sin conflictos.",
  },
  {
    icon: "fa fa-history",
    title: "Seguimiento Completo",
    description:
      "Mantén un registro detallado de todas las interacciones y vinculaciones con propiedades.",
  },
])

const startTrial = () => {
  alert("Prueba gratuita iniciada")
}

onMounted(() => {
  document.title = "Integraciones | PropMind"

  const handleScroll = () => {
    const scrollPosition = window.scrollY
    const maxScroll = 50
    const rotationAngle = Math.min(scrollPosition / maxScroll, 1) * 20

    const dashboardImg = document.querySelector(".whatsapp-screenshot")
    if (dashboardImg) {
      dashboardImg.style.transform = `perspective(1000px) rotateX(${20 - rotationAngle}deg)`
    }
  }

  document.addEventListener("scroll", handleScroll)

  // Cleanup listener on component unmount
  return () => {
    document.removeEventListener("scroll", handleScroll)
  }
})
</script>

<script>
  import CTABanner from '@/components/CTABanner.vue'
export default {
  name: "WhatsappIntegration",
  components: {
    CTABanner,
  },
}
</script>

<style scoped>
.feature-icon {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
}

.benefit-icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
}

.whatsapp-screenshot-container {
  max-width: 100%;
  padding: 120px 25px;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.whatsapp-screenshot {
  transform: perspective(1000px) rotateX(20deg);
  transition: transform 1s ease-out;
  will-change: transform;
  width: 100%;
  max-width: 1200px;
  box-shadow: 0 0 100px rgba(86, 59, 156, 0.2);
}

.integration-image {
  margin-top: 2rem;
  max-width: 100%;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .hero {
    text-align: center;
    padding: 3rem 0;
  }

  .whatsapp-screenshot-container {
    padding: 60px 15px;
  }

  .integration-image {
    margin-top: 3rem;
  }
}
</style>