<template>
  <main class="container-fluid px-2">
      <section class="hero-section position-relative overflow-hidden d-flex align-items-center">
      <div class="container">
        <div class="row align-items-center py-5 mt-5">
          <div class="col-lg-6 text-center text-lg-start">
            <h1 class="display-4 fw-bold text-primary mb-4 lh-sm text-gradient-primary">
              Multiplica tus ventas con el CRM diseñado para inmobiliarias
            </h1>
            <p class="lead mb-5">
              Gestiona propiedades, clientes y ventas en un solo lugar. <br>
              <span class="text-primary fw-semibold">Aumentá tus ventas y optimiza tus procesos</span>
            </p>
            <div class="d-flex gap-3 justify-content-center justify-content-lg-start">
              <a href="#" class="btn btn-primary px-3 py-3">
                <i class="fas fa-rocket me-2"></i>Prueba gratuita
              </a>
              <a href="#" class="btn btn-outline-primary px-3 py-3">
                <i class="fas fa-play me-2"></i>Ver demo
              </a>
            </div>
          </div>
          <div class="col-lg-6 d-none d-lg-block position-relative mt-5 mt-lg-0">
            <div class="hero-image-wrapper">
              <img src="@/assets/images/crm_screenshots/dashboard.png" alt="Dashboard Preview" class="img-fluid rounded-2" style="box-shadow: 0 0px 100px rgb(73, 0, 169, 0.3)" />
            </div>
            <div class="floating-card bg-white p-3 rounded-3 shadow position-absolute">
              <div class="d-flex align-items-center">
                <i class="fas fa-chart-line text-primary fa-2x me-3"></i>
                <div>
                  <h5 class="mb-0 fs-6">Aumentá tus ventas</h5>
                  <p class="text-muted mb-0 fs-7">Convierte más leads en clientes</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Decorative elements -->
      <div class="decoration-circle decoration-circle-1"></div>
      <div class="decoration-circle decoration-circle-2"></div>
    </section>

    <!-- Integrations Section -->
    <section class="container-fluid bg-light py-5" id="integrations">
      <div class="row g-4 d-flex justify-content-center">
        <div class="col-6 col-md-4 col-lg-2">
          <div class="stat-card p-4 rounded-4 d-flex align-items-center justify-content-center">
            <img src="@/assets/logos/zonaprop.png" alt="zonaprop logo" class="brand-logo-img">
          </div>
        </div>
        <div class="col-6 col-md-4 col-lg-2">
          <div class="stat-card p-4 rounded-4 d-flex align-items-center justify-content-center">
            <img src="@/assets/logos/mercadolibre.png" alt="mercadolibre logo" class="brand-logo-img">
          </div>
        </div>
        <div class="col-6 col-md-4 col-lg-2">
          <div class="stat-card p-4 rounded-4 d-flex align-items-center justify-content-center">
            <img src="@/assets/logos/properati.png" alt="properati logo" class="brand-logo-img">
          </div>
        </div>
        <div class="col-6 col-md-4 col-lg-2">
          <div class="stat-card p-4 rounded-4 d-flex align-items-center justify-content-center">
            <img src="@/assets/logos/inmoclick.png" alt="inmoclick logo" class="brand-logo-img">
          </div>
        </div>
        <div class="col-6 col-md-4 col-lg-2">
          <div class="stat-card p-4 rounded-4 d-flex align-items-center justify-content-center">
            <img src="@/assets/logos/olx.png" alt="olx logo" class="brand-logo-img">
          </div>
        </div>
        <div class="col-6 col-md-4 col-lg-2">
          <div class="stat-card p-4 rounded-4 d-flex align-items-center justify-content-center">
            <img src="@/assets/logos/argenprop.png" alt="argenprop logo" class="brand-logo-img">
          </div>
        </div>
      </div>
    </section>

    <section class="container py-5">
      <div class="text-center mb-5 py-5">
        <span
            class="badge rounded bg-primary-subtle text-primary px-3 py-2 mb-3 fs-5"
          >
            ¿Por qué elegirnos?
          </span>
        <h2 class="display-5 mb-4">La solución completa para tu inmobiliaria</h2>
        <p class="lead text-muted">
          Diseñado específicamente para hacer crecer tu negocio inmobiliario
        </p>
      </div>
  
      <div class="row g-4">
        <!-- Experiencia -->
        <div class="col-md-6 col-lg-3">
          <div class="card h-100 border-0 shadow-sm bg-light-hover">
            <div class="card-body text-center">
              <div class="mb-4">
                <i class="fas fa-chart-line text-primary fa-3x"></i>
              </div>
              <h5 class="card-title mb-3">Impulsa tu crecimiento</h5>
              <p class="card-text text-muted">
                Optimiza tus procesos y aumenta tus ventas con herramientas diseñadas para el éxito
              </p>
            </div>
          </div>
        </div>
  
        <!-- Soporte -->
        <div class="col-md-6 col-lg-3">
          <div class="card h-100 border-0 shadow-sm bg-light-hover">
            <div class="card-body text-center">
              <div class="mb-4">
                <i class="fas fa-headset text-primary fa-3x"></i>
              </div>
              <h5 class="card-title mb-3">Soporte dedicado</h5>
              <p class="card-text text-muted">
                Equipo de soporte especializado disponible cuando lo necesites
              </p>
            </div>
          </div>
        </div>
  
        <!-- Tecnología -->
        <div class="col-md-6 col-lg-3">
          <div class="card h-100 border-0 shadow-sm bg-light-hover">
            <div class="card-body text-center">
              <div class="mb-4">
                <i class="fas fa-laptop-code text-primary fa-3x"></i>
              </div>
              <h5 class="card-title mb-3">Tecnología moderna</h5>
              <p class="card-text text-muted">
                Plataforma actualizada constantemente con las últimas tecnologías
              </p>
            </div>
          </div>
        </div>
  
        <!-- Seguridad -->
        <div class="col-md-6 col-lg-3">
          <div class="card h-100 border-0 shadow-sm bg-light-hover">
            <div class="card-body text-center">
              <div class="mb-4">
                <i class="fas fa-shield-alt text-primary fa-3x"></i>
              </div>
              <h5 class="card-title mb-3">Máxima seguridad</h5>
              <p class="card-text text-muted">
                Tus datos siempre protegidos con los más altos estándares de seguridad
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Integration Section -->
    <section class="bg-light py-5 px-1">
      <div class="container d-flex pb-5 align-items-center">
        <div class="row d-flex gap-5 align-items-center justify-content-center">
          <div class="col-lg-4 order-md-1 order-2">
            <img
              src="@/assets/illustrations/integrations.svg"
              alt="Integraciones"
              class="img-fluid rounded-4 bg-light-hover"
              style="max-height: 400px"
            />
          </div>
          <div class="col-lg-5 mt-5 pt-md-5 pt-0 order-md-2 order-1">
            <span
            class="badge rounded bg-primary-subtle text-primary px-3 py-2 mb-3 fs-5"
          >
            <i class="fa-solid fa-link me-2"></i>
            Integraciones
          </span>
            <h2 class="display-6 mb-4">
              Conectado con tus herramientas favoritas
            </h2>
            <p class="lead text-muted mb-4">
              Integra tu CRM con las plataformas que ya utilizas para una
              gestión más eficiente.
            </p>
            <!-- conoce mas -->
            <button class="btn btn-primary">Conoce más</button>
          </div>
        </div>
      </div>
    </section>


    <!-- Pricing Section -->
    <section class="pricing-section py-5">
      <div class="container">
        <!-- Header -->
        <div class="text-center mb-5">
          <span
            class="badge rounded bg-primary-subtle text-primary px-3 py-2 mb-3 fs-5"
          >
            <i class="fa-solid fa-tag me-2"></i>precios
          </span>
          <h2 class="display-4 fw-bold mb-3">
            Elige el plan perfecto para tu negocio
          </h2>
          <p class="lead text-muted col-lg-8 mx-auto">
            Escala tu negocio inmobiliario con nuestras soluciones flexibles
          </p>
        </div>

        <!-- Pricing Cards -->
        <div class="row g-3 mb-5">
          <!-- Starter Plan -->
          <div class="col-lg-4">
            <div
              class="pricing-card card h-100 border rounded-4 bg-light-hover"
            >
              <div class="card-body p-4">
                <div class="text-center mb-4">
                  <h3 class="mb-3">Individual</h3>
                  <div class="pricing-value">
                    <span class="display-4 fw-bold">AR$25.000</span>
                    <span class="text-muted">/mes</span>
                  </div>
                </div>
                <ul class="feature-list">
                  <li>
                    <i class="fa-solid fa-check text-primary me-2"></i>2 Usuarios
                  </li>
                  <li>
                    <i class="fa-solid fa-check text-primary me-2"></i>15 Propiedades
                  </li>
                  <li>
                    <i class="fa-solid fa-check text-primary me-2"></i>5 Emprendimientos
                  </li>
                  <li>
                    <i class="fa-solid fa-check text-primary me-2"></i
                    >Sitio web
                  </li>
                  <li>
                    <i class="fa-solid fa-check text-primary me-2"></i>Certificado SSL
                  </li>
                  <li>
                    <i class="fa-solid fa-check text-primary me-2"></i>Portales gratuitos
                  </li>
                  <li class="text-muted">
                    <i class="fa-solid fa-xmark me-2"></i>Portales pagos
                  </li>
                  <li class="text-muted">
                    <i class="fa-solid fa-xmark me-2"></i>Integración con WhatsApp
                  </li>
                  <li class="text-muted">
                    <i class="fa-solid fa-xmark me-2"></i>Respuestas automaticas
                  </li>
                  <li class="text-muted">
                    <i class="fa-solid fa-xmark me-2"></i>Sincronización con calendario
                  </li>
                </ul>
                <div class=" mt-4">
                  <router-link to="/precios" class="btn btn-primary px-4 py-2">
                    Ver más detalles
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <!-- Professional Plan -->
          <div class="col-lg-4">
            <div
              class="pricing-card card h-100 border-0 rounded-4 bg-primary text-white"
            >
              <div class="card-body p-4">
                <div class="text-center mb-4">
                  <span class="badge bg-white text-primary px-3 py-2 mb-3"
                    >Más popular</span
                  >
                  <h3 class="mb-3 text-white">Equipo</h3>
                  <div class="pricing-value">
                    <span class="display-4 fw-bold">AR$40.000</span>
                    <span class="text-light">/mes</span>
                  </div>
                </div>
                <ul class="feature-list">
                  <li>
                    <i class="fa-solid fa-check text-white me-2"></i>5 Usuarios
                  </li>
                  <li>
                    <i class="fa-solid fa-check text-white me-2"></i>50 Propiedades
                  </li>
                  <li>
                    <i class="fa-solid fa-check text-white me-2"></i>10 Emprendimientos
                  </li>
                  <li>
                    <i class="fa-solid fa-check text-white me-2"></i
                    >Sitio web
                  </li>
                  <li>
                    <i class="fa-solid fa-check text-white me-2"></i>Certificado SSL
                  </li>
                  <li>
                    <i class="fa-solid fa-check text-white me-2"></i>Portales gratuitos
                  </li>
                  <li>
                    <i class="fa-solid fa-check text-white me-2"></i>Portales pagos
                  </li>
                  <li class="text-muted">
                    <i class="fa-solid fa-xmark text-muted me-2"></i>Integración con WhatsApp
                  </li>
                  <li class="text-muted">
                    <i class="fa-solid fa-xmark me-2"></i>Respuestas automaticas
                  </li>
                  <li class="text-muted text-white">
                    <i class="fa-solid fa-xmark me-2"></i>Sincronización con calendario
                  </li>
                </ul>
                <div class=" mt-4">
                  <router-link to="/precios" class="btn btn-outline-light px-4 py-2">
                    Ver más detalles
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <!-- corporativo -->
          <!-- Corporate Plan -->
          <div class="col-lg-4">
            <div class="pricing-card card h-100 border rounded-4 bg-light-hover">
              <div class="card-body p-4">
                <div class="text-center mb-4">
                  <h3 class="mb-3">Corporativo</h3>
                  <div class="pricing-value">
                    <span class="display-4 fw-bold">AR$70.000</span>
                    <span class="text-muted">/mes</span>
                  </div>
                </div>
                <ul class="feature-list">
                  <li>
                    <i class="fa-solid fa-check text-primary me-2"></i>Usuarios ilimitados
                  </li>
                  <li>
                    <i class="fa-solid fa-check text-primary me-2"></i>Propiedades ilimitadas
                  </li>
                  <li>
                    <i class="fa-solid fa-check text-primary me-2"></i>Emprendimientos ilimitados
                  </li>
                  <li>
                    <i class="fa-solid fa-check text-primary me-2"></i>Sitio web
                  </li>
                  <li>
                    <i class="fa-solid fa-check text-primary me-2"></i>Certificado SSL
                  </li>
                  <li>
                    <i class="fa-solid fa-check text-primary me-2"></i>Portales gratuitos
                  </li>
                  <li>
                    <i class="fa-solid fa-check text-primary me-2"></i>Portales pagos
                  </li>
                  <li>
                    <i class="fa-solid fa-check text-primary me-2"></i>Integración con WhatsApp
                  </li>
                  <li>
                    <i class="fa-solid fa-check text-primary me-2"></i>Respuestas automaticas
                  </li>
                  <li>
                    <i class="fa-solid fa-check text-primary me-2"></i>Sincronización con calendario
                  </li>
                </ul>
                <div class="mt-4">
                  <router-link to="/precios" class="btn btn-primary px-4 py-2">
                    Ver más detalles
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="container-fluid d-flex justify-content-center align-items-center py-5 px-0" id="faq">
      <!-- FAQ Section -->
      <div class="container row mt-6 d-flex justify-content-center px-0">
        <div class="col-lg-5 d-none d-lg-flex align-items-center justify-content-center px-0">
          <img
            src="@/assets/illustrations/faq.svg"
            alt="FAQ"
            class="img-fluid rounded-4"
          />
        </div>
        <div class="col-lg-6 px-0">
          <div class=" mb-5">
            <h2 class="display-4 fw-bold mb-3">Preguntas frecuentes</h2>
            <p class="lead text-muted mx-auto">
              Todo lo que necesitas saber sobre nuestra plataforma
            </p>
          </div>
  
          <div class="row justify-content-center">
            <div class="col-lg-12">
              <div class="accordion" id="faqAccordion">
                <div class="accordion-item border-0 shadow-sm mb-3 rounded-3">
                  <h3 class="accordion-header">
                    <button
                      class="accordion-button rounded-3"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq1"
                    >
                      ¿Puedo cambiar de plan en cualquier momento?
                    </button>
                  </h3>
                  <div
                    id="faq1"
                    class="accordion-collapse collapse show"
                    data-bs-parent="#faqAccordion"
                  >
                    <div class="accordion-body">
                      Sí, puedes actualizar o cambiar tu plan en cualquier
                      momento. Los cambios se reflejarán en tu próxima
                      facturación.
                    </div>
                  </div>
                </div>
  
                <div class="accordion-item border-0 shadow-sm mb-3 rounded-3">
                  <h3 class="accordion-header">
                    <button
                      class="accordion-button collapsed rounded-3"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq2"
                    >
                      ¿Qué métodos de pago aceptan?
                    </button>
                  </h3>
                  <div
                    id="faq2"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqAccordion"
                  >
                    <div class="accordion-body">
                      Aceptamos todas las tarjetas de crédito principales,
                      transferencias bancarias y Mercado Pago.
                    </div>
                  </div>
                </div>
  
                <div class="accordion-item border-0 shadow-sm mb-3 rounded-3">
                  <h3 class="accordion-header">
                    <button
                      class="accordion-button collapsed rounded-3"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq3"
                    >
                      ¿Ofrecen soporte técnico?
                    </button>
                  </h3>
                  <div
                    id="faq3"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqAccordion"
                  >
                    <div class="accordion-body">
                      Todos los planes incluyen soporte por email. Los planes
                      Equipo y Corporativo incluyen soporte prioritario y
                      acceso a nuestro equipo técnico.
                    </div>
                  </div>
                </div>
  
                <div class="accordion-item border-0 shadow-sm rounded-3">
                  <h3 class="accordion-header">
                    <button
                      class="accordion-button collapsed rounded-3"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq4"
                    >
                      ¿Necesito instalar algo para usar la plataforma?
                    </button>
                  </h3>
                  <div
                    id="faq4"
                    class="accordion-collapse collapse"
                    data-bs-parent="#faqAccordion"
                  >
                    <div class="accordion-body">
                      No, nuestra plataforma es 100% basada en la nube. Solo
                      necesitas un navegador web moderno para acceder.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <CTABanner class="py-5"/>
    
  </main>
</template>

<script>
  import CTABanner from "@/components/CTABanner.vue";
export default {
  name: "HomePage",
  components: {
    CTABanner,
  },
};
</script>

<style scoped>
  .floating-card {
  bottom: -70px;
  right: 0;
  transform: translateY(-50%);
}
.hero-section {
  background: linear-gradient(to bottom, #ffffff, #f8f9fa);
  min-height: 80vh;
}
.brand-logo-img {
  max-height: 40px;
  margin: 0 auto;
  filter: grayscale(100%);
  opacity: 0.5;
}
.features-section {
  background: linear-gradient(to bottom, #ffffff, #f8f9fa);
}

.feature-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.feature-icon-wrapper {
  height: 80px;
  display: flex;
  align-items: center;
}

.feature-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.feature-list li {
  padding: 0.5rem 0;
  font-weight: 500;
}

.bg-light-hover {
  background: rgba(248, 249, 250, 0.5);
}

/* pricin */
.pricing-section {
  background: linear-gradient(to bottom, #ffffff, #f8f9fa);
}

.pricing-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.pricing-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.feature-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.feature-list li {
  padding: 0.75rem 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.feature-list li:last-child {
  border-bottom: none;
}

.bg-light-hover {
  background: rgba(248, 249, 250, 0.5);
}

.rounded-4 {
  border-radius: 1rem;
}

/* end pricing */
.accordion-button:not(.collapsed) {
  background-color: var(--primary-color);
  color: white;
}

.accordion-button:focus {
  box-shadow: none;
  border-color: var(--primary-color);
}

.mt-6 {
  margin-top: 4rem;
}

.py-6 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
</style>
