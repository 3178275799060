<template>
  <div class="privacy-policy bg-light pt-5">
    <!-- Encabezado -->
    <div class="container py-4">
      <div class="row justify-content-center">
        <div class="col-lg-8 text-center">
          <h1 class="mb-3 fs-1 mt-5">Política de Privacidad y Cookies</h1>
          <p class="text-muted">Última actualización: {{ lastUpdate }}</p>
        </div>
      </div>
    </div>

    <!-- Contenido Principal -->
    <div class="container pb-5">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <!-- Secciones de Privacidad -->
          <div v-for="(section, index) in sections" 
               :key="index" 
               class="mb-4">
            <div class="card">
              <div class="card-body p-4">
                <h2 class="h4 mb-3">
                  <i :class="section.icon + ' me-2 text-primary'"></i>
                  {{ section.title }}
                </h2>
                <p class="mb-3">{{ section.description }}</p>
                
                <ul class="list-unstyled">
                  <li v-for="(item, itemIndex) in section.items" 
                      :key="itemIndex" 
                      class="mb-2">
                    <i class="fas fa-check text-success me-2"></i>
                    {{ item }}
                  </li>
                </ul>

                <div v-if="section.note" class="mt-3">
                  <p class="small text-muted">
                    <i class="fas fa-info-circle me-1"></i>
                    {{ section.note }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- Sección de Contacto -->
          <div class="card mt-5">
            <div class="card-body p-4 text-center">
              <h3 class="h5 mb-3">¿Necesitas más información?</h3>
              <p class="mb-3">Contáctanos para cualquier duda sobre tu privacidad:</p>
              <a href="mailto:privacidad@ejemplo.com" class="btn btn-primary">
                <i class="fas fa-envelope me-2"></i>
                Contactar
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
  data() {
    return {
      lastUpdate: '12 de Noviembre, 2024',
      sections: [
        {
          title: 'Recopilación de Datos',
          icon: 'fas fa-database',
          description: 'Recopilamos la siguiente información para brindarte nuestros servicios inmobiliarios:',
          items: [
            'Datos personales: nombre, correo electrónico, teléfono',
            'Datos de navegación: dirección IP, cookies, preferencias',
            'Información inmobiliaria: búsquedas, favoritos, intereses',
            'Documentos necesarios para transacciones'
          ],
          note: 'Solo recopilamos información necesaria para el servicio.'
        },
        {
          title: 'Uso de la Información',
          icon: 'fas fa-tasks',
          description: 'Utilizamos tu información para:',
          items: [
            'Gestionar tu cuenta y preferencias de búsqueda',
            'Comunicarnos contigo sobre propiedades y servicios',
            'Mejorar nuestros servicios y experiencia de usuario',
            'Compartir datos con agentes inmobiliarios autorizados',
            'Análisis estadístico y mejora del servicio'
          ]
        },
        {
          title: 'Seguridad de Datos',
          icon: 'fas fa-shield-alt',
          description: 'Protegemos tu información mediante:',
          items: [
            'Encriptación de datos sensibles',
            'Acceso restringido y autenticación segura',
            'Monitoreo constante de seguridad',
            'Copias de seguridad regulares',
            'Protocolos de seguridad actualizados'
          ]
        },
        {
          title: 'Cookies y Rastreo',
          icon: 'fas fa-cookie-bite',
          description: 'Utilizamos cookies para:',
          items: [
            'Mantener tu sesión activa',
            'Recordar tus preferencias de búsqueda',
            'Analizar el uso del sitio',
            'Personalizar tu experiencia'
          ],
          note: 'Puedes gestionar las cookies desde la configuración de tu navegador.'
        },
        {
          title: 'Tus Derechos',
          icon: 'fas fa-user-shield',
          description: 'Como usuario tienes derecho a:',
          items: [
            'Acceder a tus datos personales',
            'Solicitar correcciones de tu información',
            'Pedir la eliminación de tus datos',
            'Exportar tu información',
            'Limitar el uso de tus datos'
          ]
        },
        {
          title: 'Retención de Datos',
          icon: 'fas fa-clock',
          description: 'Mantenemos tu información:',
          items: [
            'Durante el tiempo que uses nuestros servicios',
            'Por períodos legalmente requeridos',
            'Hasta que solicites su eliminación',
            'Según necesidades de servicio justificadas'
          ]
        },
        {
          title: 'Cambios en la Política',
          icon: 'fas fa-file-alt',
          description: 'Sobre las actualizaciones de esta política:',
          items: [
            'Notificaremos cambios importantes por email',
            'Actualizaremos la fecha de última modificación',
            'Mantendremos versiones anteriores disponibles',
            'Solicitaremos consentimiento cuando sea necesario'
          ]
        }
      ]
    }
  }
}
</script>

<style scoped>
.privacy-policy {
  min-height: 100vh;
}

.card {
  border: none;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  transition: transform 0.2s ease;
}

.card:hover {
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  
  .card-body {
    padding: 1.5rem;
  }
}
</style>