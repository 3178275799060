<template>
  <footer class="container-fluid bg-light py-5">
    <div class="container">
      <!-- Brand Section -->
      <div class="row">
        <div class="col-lg-4 mb-5 mb-lg-0 text-md-start text-center">
          <img src="@/assets/logo.svg" alt="Logo" class="logo mb-4">
          <p class="text-muted px-md-0 px-5">
            Monitorea el progreso, maximiza resultados y transforma tus entrenamientos.
          </p>
          <div class="social-links">
            <a href="#" class="me-3" aria-label="LinkedIn">
              <i class="bi bi-linkedin"></i>
            </a>
            <a href="#" class="me-3" aria-label="Instagram">
              <i class="bi bi-instagram"></i>
            </a>
            <a href="#" aria-label="Twitter">
              <i class="bi bi-twitter"></i>
            </a>
          </div>
        </div>

        <div class="col-lg-8">
          <div class="row">
            <!-- Platform Links -->
            <div class="col-md-4 mb-4 text-md-start text-center">
              <h5 class="mb-4">Mapa del sitio</h5>
              <ul class="list-unstyled">
                <li class="mb-3" v-for="(item, index) in platformLinks" :key="index">
                  <a :href="item.url" class="text-decoration-none link-hover d-inline-flex align-items-center">
                    {{ item.text }}
                    <i class="bi bi-arrow-right ms-2 arrow-icon"></i>
                  </a>
                </li>
              </ul>
            </div>

            <!-- Resources -->
            <div class="col-md-4 mb-4 text-md-start text-center">
              <h5 class="mb-4">Recursos</h5>
              <ul class="list-unstyled">
                <li class="mb-3" v-for="(item, index) in resourceLinks" :key="index">
                    <a :href="item.url" class="text-decoration-none link-hover d-inline-flex align-items-center">
                      {{ item.text }}
                      <i class="bi bi-arrow-right ms-2 arrow-icon"></i>
                    </a>
                    <span v-if="item.isNew" class="badge bg-primary ms-2">Nuevo</span>
                </li>
              </ul>
            </div>

            <!-- Legal -->
            <div class="col-md-4 text-md-start text-center">
              <h5 class="mb-4">Legal</h5>
              <ul class="list-unstyled">
                <li class="mb-3" v-for="(item, index) in legalLinks" :key="index">
                  <a :href="item.url" class="text-decoration-none link-hover d-inline-flex align-items-center">
                    {{ item.text }}
                    <i class="bi bi-arrow-right ms-2 arrow-icon"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- Copyright -->
      <div class="row mt-5 pt-4 border-top">
        <div class="col-12 text-center">
          <p class="text-muted mb-0">© {{ new Date().getFullYear() }} Propmind. Todos los derechos reservados.</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter',
  data() {
    return {
      platformLinks: [
        { text: 'Beneficios', url: '#' },
        { text: 'Precios', url: '#' },
        { text: 'Integraciones', url: '#' },
      ],
      resourceLinks: [
        { text: 'Blog', url: '#', isNew: true },
        { text: 'Tutoriales', url: '#' },
        { text: 'Casos de éxito', url: '#' },
      ],
      legalLinks: [
        { text: 'Privacidad y Cookies', url: '/privacidad-y-cookies' },
        { text: 'Términos y Condiciones', url: '/terminos-y-condiciones' },
        { text: 'Seguridad', url: '/privacidad-y-cookies' },
      ]
    }
  }
}
</script>

<style scoped>
.footer {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.logo {
  max-width: 200px;
  height: auto;
}

.social-links a {
  color: #6c757d;
  font-size: 1.5rem;
  transition: all 0.3s ease;
}

.social-links a:hover {
  color: var(--bs-primary);
  transform: translateY(-2px);
}

.link-hover {
  color: #6c757d;
  transition: all 0.3s ease;
}

.link-hover:hover {
  color: var(--bs-primary);
}

.arrow-icon {
  opacity: 0;
  transform: translateX(-10px);
  transition: all 0.3s ease;
}

.link-hover:hover .arrow-icon {
  opacity: 1;
  transform: translateX(0);
}

.badge {
  font-size: 0.75rem;
  padding: 0.25em 0.75em;
}
</style>