<template>
  <div class="pricing-section py-5 mt-5 px-3">
    <div class="container">
      <div class="text-center mb-5">
        <span class="badge bg-primary-subtle text-primary my-3 fs-5">Planes y Precios</span>
        <h2 class="display-5 fw-bold mb-3">Encuentra el plan perfecto para tu negocio</h2>
        <p class="text-muted lead">Compara nuestros planes y elige el que mejor se adapte a tus necesidades</p>
      </div>

      <!-- Tabs para móvil -->
      <div class="d-md-none mb-4">
        <div class="nav nav-pills nav-fill" role="tablist">
          <button 
            v-for="(plan, index) in ['Individual', 'Equipo', 'Corporativo']" 
            :key="index"
            class="nav-link" 
            :class="{ active: activePlan === index }"
            @click="activePlan = index">
            {{ plan }}
          </button>
        </div>
        
        <!-- Contenido de las tabs -->
        <div class="card mt-3 border-0 shadow-sm">
          <div class="card-body">
            <div v-for="(feature, index) in features" :key="index" class="mb-3">
              <div class="d-flex align-items-center mb-2">
                <span class="feature-name fw-bold fs-7">{{ feature.name }}</span>
                <i v-if="feature.tooltip" 
                   class="fas fa-info-circle text-muted ms-2"
                   data-bs-toggle="tooltip"
                   :title="feature.tooltip"></i>
              </div>
              <div class="feature-value fs-6">
                <template v-if="typeof feature[planTypes[activePlan]] === 'boolean'">
                  <i :class="feature[planTypes[activePlan]] ? 'fas fa-check text-success' : 'fas fa-times text-muted'"></i>
                </template>
                <span v-else>{{ feature[planTypes[activePlan]] }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Tabla para desktop -->
      <div class="d-none d-md-block">
        <div class="table-responsive">
          <table class="table comparison-table">
            <thead>
              <tr>
                <th scope="col" class="bg-transparent feature-column">
                  <div class="p-4">
                    <h4 class="text-muted mb-0 fs-5">Características</h4>
                  </div>
                </th>
                <th v-for="(plan, index) in plans" :key="index" 
                    :class="{'popular-plan': plan.popular}"
                    class="text-center plan-column">
                  <div class="plan-header p-4">
                    <span v-if="plan.popular" 
                          class="badge bg-white text-primary position-absolute top-0 start-50 translate-middle-x mt-2">
                      Más popular
                    </span>
                    <h3 class="fw-bold my-2">{{ plan.name }}</h3>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(feature, index) in features" 
                  :key="index"
                  :class="{'bg-light': index % 2 === 0}">
                <td class="feature-name p-4 fw-bold">
                  <div class="d-flex align-items-center">
                    {{ feature.name }}
                    <i v-if="feature.tooltip" 
                       class="fas fa-info-circle text-muted ms-2"
                       data-bs-toggle="tooltip"
                       data-bs-placement="top"
                       :title="feature.tooltip"></i>
                  </div>
                </td>
                <td v-for="plan in planTypes" 
                    :key="plan" 
                    class="text-center p-4">
                  <div class="feature-value">
                    <template v-if="typeof feature[plan] === 'boolean'">
                      <i :class="[
                        'fas', 
                        feature[plan] ? 'fa-check-circle text-success' : 'fa-times-circle text-muted',
                        'fa-lg'
                      ]"></i>
                    </template>
                    <span v-else 
                          :class="{'fw-bold': feature[plan] === 'Ilimitado' || feature[plan] === 'Ilimitadas' || feature[plan] === 'Ilimitados'}">
                      {{ feature[plan] }}
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- CTA Section -->
      <div class="help-section text-center mt-5 py-4">
        <div class="row justify-content-center">
          <div class="col-lg-6">
            <h4 class="mb-3">¿Necesitas ayuda para elegir el plan adecuado?</h4>
            <p class="text-muted mb-4">Nuestro equipo está disponible para asesorarte en la mejor opción para tu negocio</p>
            <button class="btn btn-primary btn-lg px-5">
              <i class="fas fa-comments me-2"></i>
              Contáctanos
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, onMounted } from 'vue'
  import * as bootstrap from 'bootstrap'
  
  export default {
    name: 'PlanComparison',
    setup() {
      const activePlan = ref(0)
      
      const planTypes = ['basic', 'professional', 'enterprise']
      
      const plans = [
        { name: 'Individual', type: 'basic', popular: false },
        { name: 'Equipo', type: 'professional', popular: true },
        { name: 'Corporativo', type: 'enterprise', popular: false }
      ]
  
      const features = [
        {
          name: 'Propiedades',
          basic: '30 Propiedades',
          professional: 'Ilimitadas',
          enterprise: 'Ilimitadas',
          tooltip: 'Número máximo de propiedades que puede publicar simultáneamente'
        },
        {
          name: 'Emprendimientos',
          basic: '1 Emprendimiento',
          professional: '3 Emprendimientos',
          enterprise: 'Ilimitados'
        },
        {
          name: 'Sitio web',
          basic: 'Básico',
          professional: 'Personalizado',
          enterprise: 'Premium',
          tooltip: 'Nivel de personalización y funcionalidades del sitio web'
        },
        {
          name: 'Certificado SSL',
          basic: true,
          professional: true,
          enterprise: true
        },
        {
          name: 'Correos electrónicos',
          basic: '2 Correos',
          professional: '5 Correos',
          enterprise: 'Ilimitados'
        },
        {
          name: 'Usuarios',
          basic: '2 Usuarios',
          professional: '5 Usuarios',
          enterprise: 'Ilimitados'
        },
        {
          name: 'Portales gratuitos',
          basic: true,
          professional: true,
          enterprise: true
        },
        {
          name: 'Portales pagos',
          basic: false,
          professional: true,
          enterprise: true,
          tooltip: 'Integración con portales inmobiliarios premium (MercadoLibre, ZonaProp, etc.)'
        },
        {
          name: 'Páginas web por propiedad',
          basic: false,
          professional: true,
          enterprise: true,
          tooltip: 'Landing pages personalizadas para cada propiedad'
        },
        {
          name: 'Sincronización con calendario',
          basic: false,
          professional: true,
          enterprise: true
        },
        {
          name: 'Respuestas automáticas',
          basic: false,
          professional: false,
          enterprise: true,
          tooltip: 'Automatización de respuestas para consultas frecuentes'
        },
        {
          name: 'Plantillas de email',
          basic: false,
          professional: false,
          enterprise: true
        },
        {
          name: 'Integración WhatsApp',
          basic: false,
          professional: false,
          enterprise: true,
          tooltip: 'Gestión multiusuario de chats de WhatsApp'
        }
      ]
  
      onMounted(() => {
        // Inicializar tooltips de Bootstrap
        const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        tooltipTriggerList.map(function (tooltipTriggerEl) {
          return new bootstrap.Tooltip(tooltipTriggerEl)
        })
      })
  
      return {
        activePlan,
        planTypes,
        plans,
        features
      }
    }
  }
  </script>

<style scoped>
.pricing-section {
  background-color: #f8f9fa;
}

.comparison-table {
  background-color: white;
  border-radius: 1rem;
  overflow: hidden;
}

.feature-column {
  min-width: 280px;
}

.plan-column {
  min-width: 220px;
  background-color: var(--white-color);
}

.popular-plan {
  background-color: var(--primary-color);
  position: relative;
}
.popular-plan h3 {
  color: var(--white-color);
}

.plan-header {
  position: relative;
}

.feature-name {
  color: var(--bs-gray-700);
  font-weight: 500;
}

.feature-value {
  font-weight: 500;
}

.table > :not(caption) > * > * {
  border-bottom: none;
  box-shadow: inset 0 0 0 9999px transparent;
}

.nav-pills .nav-link {
  border-radius: 0.5rem;
  padding: 0.75rem 1rem;
  color: var(--bs-gray-700);
  transition: all 0.2s ease-in-out;
}

.nav-pills .nav-link.active {
  background-color: var(--primary-color);
  color: white;
}

.help-section {
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

@media (max-width: 768px) {
  .feature-name {
    font-size: 0.9rem;
  }
  
  .feature-value {
    font-size: 0.9rem;
  }
}

/* Animaciones */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

.comparison-table {
  animation: fadeIn 0.5s ease-out;
}

.nav-pills .nav-link {
  transition: all 0.3s ease;
}

.nav-pills .nav-link:hover {
  transform: translateY(-2px);
}
</style>