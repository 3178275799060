<template>
    <div class="portals-integration mt-5 pt-5 px-3">
      <!-- Hero Section -->
      <section class="hero bg-gradient py-5 my-5">
        <div class="container">
          <div class="row align-items-center justify-content-between">
            <div class="col-lg-6 text-start pe-lg-5">
              <h1 class="display-4 fw-bold mb-3">
                Publica en Todos los Portales desde Un Solo Lugar
              </h1>
              <p class="lead mb-3">
                Gestiona tus propiedades en los principales portales inmobiliarios sin salir de PropMind
              </p>
              <button
                @click="startTrial"
                class="btn btn-primary btn-lg px-4 me-3"
              >
                Comenzar Ahora
              </button>
            </div>
            <div class="col-lg-6 mt-5 mt-lg-0">
              <img
                src="@/assets/illustrations/portals.svg"
                alt="Dashboard de publicación en portales"
                class="px-0 rounded-4 col-lg-10"
                style="max-width: 100%;"
              />
            </div>
          </div>
        </div>
      </section>
  
      <!-- Features Section -->
      <section class="features py-5">
        <div class="container">
          <h2 class="text-center mb-5">Características Principales</h2>
          <div class="row g-4">
            <div
              v-for="(feature, index) in features"
              :key="index"
              class="col-md-4"
            >
              <div class="card h-100 border-0 shadow-sm">
                <div class="card-body p-4">
                  <div
                    class="feature-icon bg-primary bg-gradient text-white rounded-circle p-3 mb-4"
                  >
                    <i :class="feature.icon"></i>
                  </div>
                  <h3 class="h5 mb-3">{{ feature.title }}</h3>
                  <p class="card-text text-muted">{{ feature.description }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  
      <!-- Benefits Section -->
      <section class="benefits pb-5">
        <div class="container">
          <div class="row align-items-center justify-content-between px-1">
            <div class="col-lg-5">
              <h2 class="mb-4">Beneficios de la Integración</h2>
              <div v-for="(benefit, index) in benefits" :key="index" class="mb-4">
                <div class="d-flex align-items-center">
                  <div class="benefit-icon me-3">
                    <i :class="benefit.icon + ' text-primary fs-2'"></i>
                  </div>
                  <div>
                    <h4 class="h6 mb-2">{{ benefit.title }}</h4>
                    <p class="text-muted mb-0">{{ benefit.description }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-5">
              <div class="position-relative">
                <img
                  src="@/assets/illustrations/integrations.svg"
                  alt="Publicación multiplataforma"
                  class="img-fluid rounded-4 integration-image"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <CTABanner />
  </template>
  
  <script setup>
  import { onMounted, ref } from 'vue'
  
  const features = ref([
    {
      icon: "fa fa-sync",
      title: "Publicación Simultánea",
      description:
        "Publica tus propiedades en múltiples portales con un solo clic, ahorrando tiempo y evitando errores.",
    },
    {
      icon: "fa fa-edit",
      title: "Actualización Centralizada",
      description:
        "Modifica la información de tus propiedades una vez y actualiza automáticamente en todos los portales.",
    },
    {
      icon: "fa fa-chart-line",
      title: "Métricas Unificadas",
      description:
        "Analiza el rendimiento de tus publicaciones en todos los portales desde un único dashboard.",
    },
  ])
  
  const benefits = ref([
    {
      icon: "fa fa-clock",
      title: "Ahorro de Tiempo",
      description:
        "Reduce hasta un 90% el tiempo dedicado a la publicación y gestión de propiedades.",
    },
    {
      icon: "fa fa-bullseye",
      title: "Mayor Alcance",
      description:
        "Maximiza la visibilidad de tus propiedades llegando a más compradores potenciales.",
    },
    {
      icon: "fa fa-tasks",
      title: "Control Total",
      description:
        "Gestiona todas tus publicaciones, leads y estadísticas desde una única plataforma.",
    },
  ])
  
  const startTrial = () => {
    alert("Prueba iniciada")
  }
  
  onMounted(() => {
    document.title = "Integración Portales | PropMind"
  
    const handleScroll = () => {
      const scrollPosition = window.scrollY
      const maxScroll = 50
      const rotationAngle = Math.min(scrollPosition / maxScroll, 1) * 20
  
      const dashboardImg = document.querySelector(".portals-dashboard")
      if (dashboardImg) {
        dashboardImg.style.transform = `perspective(1000px) rotateX(${20 - rotationAngle}deg)`
      }
    }
  
    document.addEventListener("scroll", handleScroll)
  
    // Cleanup listener on component unmount
    return () => {
      document.removeEventListener("scroll", handleScroll)
    }
  })
  </script>
  
  <script>
    import CTABanner from '@/components/CTABanner.vue'
  export default {
    name: "PortalsIntegration",
    components: {
      CTABanner,
    },
  }
  </script>
  
  <style scoped>
  .feature-icon {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
  }
  
  .benefit-icon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.25rem;
  }
  
  .portals-dashboard-container {
    max-width: 100%;
    padding: 120px 25px;
    overflow: hidden;
    display: flex;
    justify-content: center;
  }
  
  .portals-dashboard {
    transform: perspective(1000px) rotateX(20deg);
    transition: transform 1s ease-out;
    will-change: transform;
    width: 100%;
    max-width: 1100px;
    box-shadow: 0 0 100px rgba(86, 59, 156, 0.2);
  }
  
  .integration-image {
    margin-top: 2rem;
    max-width: 100%;
  }
  
  .portal-logo {
    height: 60px;
    width: auto;
    object-fit: contain;
  }
  
  .portal-card {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .portal-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1) !important;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .hero {
      text-align: center;
      padding: 3rem 0;
    }
  
    .portals-dashboard-container {
      padding: 60px 15px;
    }
  
    .integration-image {
      margin-top: 3rem;
    }
  
    .portal-logo {
      height: 40px;
    }
  }
  </style>