<template> 
  <AppNavbar />
  <router-view></router-view>
  <AppFooter />
</template>
<script>
  import AppNavbar from './components/Navbar.vue';
  import AppFooter from './components/Footer.vue';

export default {
  name: 'App',
  components: {
    AppNavbar,
    AppFooter,
  }
}
</script>

<style>
  
  /* archivo  */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
  
}
:root {
  --white-color: #eaeaea;
  --grey-color: #e7e7e7bb;
  --bg-color: #ffffff;
  --black-color: #1c1c1c;
  --black-color-2: #353535;
  --primary-color: #563B9C;
  --light-primary-color: #563B9C25;
  --dark-color: #585858;
  --dark-primary-color: #3d2970;
  --dark-primary-color-2: #563b9c55;
  --secondary-color: #555555;
  --accent-color: #5f28cd;
}
.text-gradient-primary{
  background: linear-gradient(100deg, var(--primary-color), var(--dark-color));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.fs-7{
  font-size: 0.8rem;
}
.bg-accent{
  background-color: var(--accent-color) !important; 
}
.bg-primary-subtle{
  background-color: var(--light-primary-color); 
}
/* modifico los componentes de bootstrap para que el primary sea el mio */
.btn-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  padding: .875rem 1.3rem;
}
.btn-primary:hover {
  background-color: var(--dark-primary-color);
  border-color: var(--dark-primary-color);
}
.btn-primary:focus {
  background-color: var(--dark-primary-color-2);
  border-color: var(--dark-primary-color-2);
}
.btn-outline-primary {
  color: var(--primary-color);
  border-color: var(--primary-color);
  padding: .875rem 1.3rem;
}
.btn-outline-primary:hover {
  color: var(--white-color);
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
.btn-outline-primary:focus {
  color: var(--white-color);
  background-color: var(--dark-primary-color);
  border-color: var(--dark-primary-color);
}
.text-primary {
  color: var(--primary-color) !important;
}
.bg-primary {
  background-color: var(--primary-color) !important;
}
.text-muted {
  color: var(--grey-color);
}
/* modifico el color de los iconos de bootstrap */
.bi {
  color: var(--primary-color);
}
body {
  background-color: var(--bg-color);
}
.container{
  max-width: 1400px;
  padding: 0;
}
.rounded-4 {
  border-radius: 5rem;
}
.text-white-75 {
  color: rgba(255, 255, 255, 0.75);
}
section{
  position: relative;
  overflow: hidden;
}
button,a{
  font-weight: 400;
  font-size: 1.1rem;}
h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
  color: var(--black-color);
}
h1 {
  font-size: 2rem;
}
h2 {
  font-size: 1.7rem;
}
h3 {
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: 1px;
}
h4 {
  font-size: 1.5rem;
  font-weight: 400;
}
p{
  font-size: 1rem;
  color: var(--black-color-2);
  font-weight: 400;
}
</style>
